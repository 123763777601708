import { CONVEYANCE_TYPES, type Location } from '@koala/sdk/v4';
import { useState } from 'react';
import { getFulfillmentTime } from '../menu-availability/conveyance';
import GenericModal from '@/components/uielements/genericModal';
import {
  StyledGenericModalContent,
  StyledGenericModalFooter,
} from '@/components/uielements/genericModal/styles';
import { isStoreCurrentlyOpen } from '@/utils/locations';
import { MODAL } from '@/constants/events';
import { type IBasketState } from '@/types/basket';
import { type IConveyanceModeState } from '@/types/conveyanceMode';
import { type ILocationsState } from '@/types/locations';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';

interface Props {
  basket: IBasketState;
  address: IConveyanceModeState['address'];
  location?: ILocationsState | Location;
  timeWanted: string;
}

export const ConveyanceReconciliation = ({ basket, address, location, timeWanted }: Props) => {
  //@ts-expect-error operating_hours is not on type ILocationsState
  const isOpen = Boolean(isStoreCurrentlyOpen(location?.operating_hours));
  const [modalShown, setModalShown] = useState<boolean>(!isOpen);

  return (
    <div>
      {/* Alert if outside of business/operating hours */}
      <GenericModal modalOpen={modalShown} name={MODAL.OUTSIDE_OPERARING_HOURS} header={''}>
        <StyledGenericModalContent>
          <div style={{ padding: '40px 20px 20px 20px' }}>
            Your earliest available{' '}
            {
              //@ts-expect-error
              basket?.fulfillment?.type || address?.type || CONVEYANCE_TYPES.PICKUP
            }{' '}
            time is:
            <br></br>
            {getFulfillmentTime(timeWanted, true)}
          </div>
        </StyledGenericModalContent>
        <StyledGenericModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledPrimaryButton
            data-css-override="StyledPrimaryButton"
            aria-label="Got it"
            onClick={() => setModalShown(false)}
          >
            Start Order
          </StyledPrimaryButton>
        </StyledGenericModalFooter>
      </GenericModal>
    </div>
  );
};
